<template>
  <div>
    <el-container>
      <el-header height="100%">
        <component :is="headerComName" />
        <div class="carousel hidden-sm-and-down">
          <el-carousel
            :height="curHeight - 60 + 'px'"
            autoplay
            :interval="6500"
          >
            <el-carousel-item
              v-for="(item, index) in carouselData"
              :key="index"
            >
              <div class="small">
                <a
                  :href="item.URL == '' ? 'javascript:return false;' : item.URL"
                  target="_blank"
                >
                  <video
                    v-show="item.CONTENT.indexOf('mp4') != -1"
                    width="100%"
                    autoplay
                    muted
                    loop
                    :src="item.CONTENT"
                  ></video>
                  <img
                    ref="curImg"
                    @load="curLoad"
                    v-show="item.CONTENT.indexOf('mp4') == -1"
                    width="100%"
                    :src="item.CONTENT"
                    alt=""
                  />
                  <div class="car-card">
                    <div class="line" v-show="item.CTIME == 'true'">
                      <img
                        width="100%"
                        src="../../assets/images/HOME/cur-line.png"
                        alt=""
                      />
                    </div>
                    <div class="car-txt" v-show="item.STEMFROM == 'true'">
                      <div class="car-txt-en" v-html="item.SHORTCONTENT"></div>
                      <div class="car-txt-cn">
                        <p>{{ item.TITLE }}</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="carousel hidden-md-and-up">
          <el-carousel :height="bannerHeight + 'px'">
            <template v-for="(item, index) in carouselData">
              <el-carousel-item
                v-if="item.CONTENT.indexOf('mp4') == -1"
                :key="index"
              >
                <div class="small">
                  <a
                    :href="
                      item.URL == '' ? 'javascript:return false;' : item.URL
                    "
                    target="_blank"
                  >
                    <img
                      ref="bannerHeight"
                      @load="imgLoad"
                      width="100%"
                      :src="item.IMAGE"
                      alt=""
                    />
                    <div class="car-card">
                      <div class="line" v-show="item.CTIME == 'true'">
                        <img
                          width="100%"
                          src="../../assets/images/HOME/cur-line.png"
                          alt=""
                        />
                      </div>
                      <div class="car-txt" v-show="item.STEMFROM == 'true'">
                        <div
                          class="car-txt-en"
                          v-html="item.SHORTCONTENT"
                        ></div>
                        <div class="car-txt-cn">
                          <p>{{ item.TITLE }}</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </el-carousel-item>
            </template>
          </el-carousel>
        </div>
      </el-header>
      <div class="home-content">
        <div class="popup2-box" v-show="popupShow2">
          <div class="popup-card">
            <div class="popup-header">
              <i @click="popup2Close" class="el-icon-close"></i>
            </div>
            <div
              class="popup-img"
              :style="{ cursor: popupData.coverLink ? 'pointer' : 'unset' }"
              @click="goEvent(popupData.coverLink)"
              style="padding: 0;"
            >
              <img
                style="display: block"
                width="100%"
                :src="popupData.coverUrl"
                alt=""
              />
            </div>
            <div class="letter-list">
              <ul>
                <li v-for="(item, index) in popupData.list" :key="index">
                  <div
                    class="txt-box"
                    style="cursor: pointer"
                    @click="goEvent($i18n.locale === 'en' ? item.enLink : item.cnLink)"
                  >
                    <div class="en-txt">{{ item.enTitle }}</div>
                    <div class="cn-txt">
                      {{ item.cnTitle }}
                    </div>
                  </div>
                  <div class="btn-box">
                    <div
                      class="more-btn"
                      v-show="item.btnShow"
                      @click="goEvent($i18n.locale === 'en' ? item.enLink : item.cnLink)"
                    >
                      {{ item.btnCTxt }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>        
      </div>
      <el-main class="CityNew">
        <div class="w">
          <div class="kr-content">
            <div class="kr-s4">
              <div class="kr-row">
                <div
                  v-for="item in pageTargetList"
                  :key="item.id"
                  class="kr-col"
                >
                  <div class="kr-box">
                    <img class="g1" :src="s1ImgPath" alt="" />
                    <a class="kr-t1" :href="'#' + item.id">{{ item.title }}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="kr-s5">
              <div class="kr-row1" :id="pageTargetList[0].id">
                <img :src="pageTargetList[0].img" alt="" />
              </div>
              <div class="kr-row2">
                <div v-for="item in s2List" :key="item.id" class="kr-col">
                  <div class="kr-box" @click="handleToActiveList(item)">
                    <img class="g1" :src="item.img" alt="" />
                  </div>
                  <div class="kr-t1">{{ item.title }}</div>
                </div>
              </div>
            </div>
            <div class="kr-s6">
              <div class="kr-row1" :id="pageTargetList[1].id">
                <img :src="pageTargetList[1].img" alt="" />
              </div>
              <div
                class="kr-row2"
                v-loading="listLoading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              >
                <div
                  v-for="row in list"
                  :key="row.key"
                  class="kr-col"
                  @click="handleToVideoDetail(row)"
                >
                  <div class="kr-col1">
                    <div class="g1Box">
                      <video class="g1" playsinline preload="metadata">
                        <source :src="row.video + '#t=1'" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                  <div class="kr-col2">
                    <div class="kr-t1">{{ row.title }}</div>
                    <div class="kr-t2">
                      {{ row.stateDate | format("yyyy.MM.dd") }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="kr-row3">
                <div class="kr-btn1" @click="handleToVideoList">
                  {{ $i18n.locale === "en" ? "more" : "更多" }} ‧‧‧
                </div>
              </div>
            </div>
            <div class="kr-s7">
              <div class="kr-row1" :id="pageTargetList[2].id">
                <img :src="pageTargetList[2].img" alt="" />
              </div>
              <div class="kr-row2">
                <CityF1E v-if="$i18n.locale === 'en'" hideLayout />
                <CityF1 v-else hideLayout />
              </div>
            </div>
            <div class="kr-s8">
              <div class="kr-row1" :id="pageTargetList[3].id">
                <img :src="pageTargetList[3].img" alt="" />
              </div>
              <div
                class="kr-row2"
                v-loading="listLoading2"
                element-loading-background="rgba(0, 0, 0, 0.8)"
              >
                <img :src="s4ImgPath" class="g1" alt="" />
                <div class="kr-row2-1">
                  <div class="kr-t1">
                    {{ $i18n.locale === "en" ? "Name" : "姓名" }}:
                  </div>
                  <div>
                    <el-input
                      v-model="messageForm.userName"
                      size="medium"
                      :placeholder="$i18n.locale === 'en' ? 'Name' : '姓名'"
                    />
                  </div>
                </div>
                <div class="kr-row2-1">
                  <div class="kr-t1">
                    {{ $i18n.locale === "en" ? "Content" : "内容" }}:
                  </div>
                  <div style="flex: auto;">
                    <el-input
                      type="textarea"
                      :rows="5"
                      size="medium"
                      v-model="messageForm.content"
                      :placeholder="$i18n.locale === 'en' ? 'Content' : '内容'"
                    />
                  </div>
                </div>
                <div class="kr-row2-2">
                  <div class="kr-btn1" @click="sendMessage">
                    {{ $i18n.locale === "en" ? "Send" : "发送" }}
                  </div>
                </div>
                <div class="kr-row2-3">
                  {{ $i18n.locale === "en" ? "Message board" : "留言板" }}:
                </div>
                <div class="kr-row2-4">
                  <div
                    v-for="item in messageList"
                    :key="item.key"
                    class="kr-row2-41"
                  >
                    <div class="kr-t2">{{ item.userName }}</div>
                    <div class="kr-t3">
                      {{ item.createTime | format("yyyy.MM.dd") }}
                    </div>
                    <div class="kr-t4">{{ item.content }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <gotop></gotop>
      </el-main>
      <el-footer>
        <component :is="footerComName" />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Gotop from "../../components/gotop";
import { mapGetters } from "vuex";
import axios from "axios";
import { cityHomea } from "../../api";
import { cityHomea as cityHomea2 } from "../../api-en";
import CityF1 from "@/views/zh/CityF1";
import CityF1E from "@/views/en/CityF1";

export default {
  components: {
    Gotop,
    CityF1,
    CityF1E
  },
  data() {
    return {
      popupShow2: false,
      popupData: [],
      curHeight: 0,
      bannerHeight: 0,
      carouselData: [],
      listQuery: {
        page: 1,
        limit: 4,
        searchTxt: ""
      },
      total: 0,
      list: [],
      listLoading: false,
      listLoading2: false,
      messageForm: {
        userName: "",
        content: ""
      },
      messageList: []
    };
  },
  computed: {
    ...mapGetters([
      "devicePathStr",
      "langPathStr",
      "headerComName",
      "footerComName",
      "curHide"
    ]),
    s1ImgPath() {
      return require(`../../assets/images/H/home-s1${this.devicePathStr}.png`);
    },
    s4ImgPath() {
      return require(`../../assets/images/H/home-s4${this.devicePathStr}.png`);
    },
    pageTargetList() {
      return [
        {
          title: this.curHide === "zh" ? "品牌活动" : "Branding Activities",
          id: "homeSection1",
          img: require(`../../assets/images/H/home-s3-1${this.langPathStr}${
            this.devicePathStr
          }.png`)
        },
        {
          title: this.curHide === "zh" ? "相关视频" : "Related Video",
          id: "homeSection2",
          img: require(`../../assets/images/H/home-s3-2${this.langPathStr}${
            this.devicePathStr
          }.png`)
        },
        {
          title: this.curHide === "zh" ? "关于我们" : "About Us",
          id: "homeSection3",
          img: require(`../../assets/images/H/home-s3-3${this.langPathStr}${
            this.devicePathStr
          }.png`)
        },
        {
          title: this.curHide === "zh" ? "联系我们" : "Contact Us",
          id: "homeSection4",
          img: require(`../../assets/images/H/home-s3-4${this.langPathStr}${
            this.devicePathStr
          }.png`)
        }
      ];
    },
    s2List() {
      return [
        {
          key: "1",
          img: require(`../../assets/images/H/home-s2-1${
            this.langPathStr
          }.png`),
          routeName: this.$i18n.locale === "en" ? "ShortVideoe" : "ShortVideo",
          title: this.$i18n.locale === "en" ? "Short Video" : "短视频大赛"
        },
        {
          key: "2",
          img: require(`../../assets/images/H/home-s2-2${
            this.langPathStr
          }.png`),
          routeName:
            this.$i18n.locale === "en" ? "Internationale" : "International",
          title: this.$i18n.locale === "en" ? "IInternational" : "国际化在身边"
        },
        {
          key: "3",
          img: require(`../../assets/images/H/home-s2-3${
            this.langPathStr
          }.png`),
          routeName:
            this.$i18n.locale === "en" ? "Communitiese" : "Communities",
          title: this.$i18n.locale === "en" ? "Communities" : "国际社群活动"
        },
        {
          key: "4",
          img: require(`../../assets/images/H/home-s2-4${
            this.langPathStr
          }.png`),
          routeName: this.$i18n.locale === "en" ? "Exchangee" : "Exchange",
          title: this.$i18n.locale === "en" ? "Exchange" : "留学生菁英交流营"
        },
        {
          key: "5",
          img: require(`../../assets/images/H/home-s2-5${
            this.langPathStr
          }.png`),
          routeName: this.$i18n.locale === "en" ? "OtherEvente" : "OtherEvent",
          title: this.$i18n.locale === "en" ? "Other Event" : "其他活动"
        }
      ];
    },
    topImgPath() {
      return require(`../../assets/images/H/topImg-${this.$route.meta.type}${
        this.devicePathStr
      }.png`);
    },
    g1ImgPath() {
      return require(`../../assets/images/H/g1-${this.$route.meta.type}${
        this.langPathStr
      }${this.devicePathStr}.png`);
    },
    g2ImgPath() {
      return require(`../../assets/images/H/g2${this.langPathStr}${
        this.devicePathStr
      }.png`);
    }
  },
  mounted() {
    // this.popupShow2 = true
    // $.ajax({
    //   async: true,
    //   url: "https://www.cityplus.com/static/json/popupData.json",
    //   type: "GET",
    //   dataType: "jsonp", // 返回的数据类型，设置为JSONP方式
    //   jsonp: "callback", //指定一个查询参数名称来覆盖默认的 jsonp 回调参数名 callback
    //   jsonpCallback: "callbackFun", //设置回调函数名
    //   success: res => {
    //     this.popupData = res.data;
    //   }
    // });
    this.getList();
    this.getList2();
    if (this.$i18n.locale === "en") {
      cityHomea2().then(res => {
        this.carouselData = res.data;
      });
    } else {
      cityHomea().then(res => {
        this.carouselData = res.data;
      });
    }
    window.addEventListener(
      "resize",
      () => {
        this.curLoad();
        this.imgLoad();
      },
      false
    );
  },
  methods: {
    goEvent(link) {
      if (link) window.open(link);
    },
    popup2Close() {
      this.popupShow2 = false;
    },
    curLoad() {
      this.$nextTick(() => {
        this.curHeight = this.$refs.curImg[1].height;
      });
    },
    imgLoad() {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight[1].height;
      });
    },
    handleToVideoList() {
      this.$router.push({
        name: this.$i18n.locale === "en" ? "VideoListe" : "VideoList"
      });
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    async getList() {
      this.listLoading = true;
      try {
        const res = await axios.get(`web/activity${"/video"}/list`, {
          params: {
            type: this.$route.meta.type,
            hide: this.curHide,
            pageNum: this.listQuery.page,
            pageSize: this.listQuery.limit,
            keyword: this.listQuery.searchTxt
          }
        });
        if (res && res.data && res.data.code === 200) {
          this.list = res.data.data.list;
          this.total = res.data.data.total;
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
      this.listLoading = false;
    },
    async getList2() {
      this.listLoading2 = true;
      try {
        const res = await axios.get(`web/message/all`, {
          params: {
            hide: this.curHide,
            isShow: "1"
          }
        });
        if (res && res.data && res.data.code === 200) {
          this.messageList = res.data.data;
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
      this.listLoading2 = false;
    },
    async sendMessage() {
      if (!this.messageForm.userName) {
        this.$message({
          message:
            this.$i18n.locale === "en"
              ? "Please enter your name"
              : "请输入姓名",
          type: "warning"
        });
        return;
      }
      if (!this.messageForm.content) {
        this.$message({
          message:
            this.$i18n.locale === "en"
              ? "Please enter the content"
              : "请输入内容",
          type: "warning"
        });
        return;
      }
      this.listLoading2 = true;
      try {
        const res = await axios.post(`web/message/create`, {
          userName: this.messageForm.userName,
          content: this.messageForm.content,
          hide: this.curHide
        });
        if (res && res.data && res.data.code === 200) {
          this.$message({
            message:
              this.$i18n.locale === "en" ? "Send successfully" : "发送成功",
            type: "success"
          });
          this.messageForm.userName = "";
          this.messageForm.content = "";
          this.getList2();
        }
        console.log(res);
      } catch (error) {
        console.log(error);
      }
      this.listLoading2 = false;
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleToDetail(row) {
      console.log(row);
      this.$router.push({
        name: this.$i18n.locale === "en" ? "ActiveDetaile" : "ActiveDetail",
        params: {
          detailId: row.id
        }
      });
    },
    handleToVideoDetail(row) {
      console.log(row);
      this.$router.push({
        name: this.$i18n.locale === "en" ? "VideoDetaile" : "VideoDetail",
        params: {
          detailId: row.id
        }
      });
    },
    handleToActiveList(row) {
      this.$router.push({
        name: row.routeName
      });
    }
  }
};
</script>

<style lang="less" scoped src="../../assets/css/City.less"></style>
<style scoped lang="less">
.CityNew {
  font-family: Microsoft YaHei, Microsoft YaHei;

  .kr-content {
    max-width: 1200px;
    margin: auto;
    position: relative;
    padding-bottom: 60px;

    .kr-s4 {
      padding-top: 20px;

      .kr-row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 20px;

        .kr-col {
          .kr-box {
            width: 240px;
            height: 176px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .g1 {
              position: absolute;
              z-index: -1;
              width: 100%;
              height: 100%;
            }

            .kr-t1 {
              font-size: 20px;
              color: #00b6cd;
              font-weight: bold;
            }
          }
        }
      }
    }

    .kr-s5 {
      .kr-row1 {
        padding-top: 90px;
        padding-bottom: 40px;
      }

      .kr-row2 {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 24px;

        .kr-col {
          .kr-box {
            width: 312px;
            height: 220px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            overflow: hidden;

            .g1 {
              position: absolute;
              z-index: -1;
              width: 100%;
              height: 100%;
            }
          }

          .kr-t1 {
            padding-top: 4px;
            font-size: 18px;
            color: #00b6cd;
            font-weight: bold;
          }
        }
      }
    }

    .kr-s6 {
      .kr-row1 {
        padding-top: 90px;
        padding-bottom: 40px;
      }

      .kr-row2 {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 32px;

        .kr-col {
          width: 400px;

          .kr-col1 {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .g1Box {
              position: relative;
              padding-right: 100%;
              padding-bottom: 56.3%;
              background: #000;
              overflow: hidden;
              border-radius: 8px;

              .g1 {
                position: absolute;
                width: 100%;
                height: 100%;
              }
            }
          }

          .kr-col2 {
            padding-top: 16px;
            text-align: left;

            .kr-t1 {
              font-size: 16px;
              color: #000000;
              min-height: 56px;
              max-height: 56px;
              -webkit-line-clamp: 2;
            }

            .kr-t2 {
              padding-top: 10px;
              font-size: 16px;
              color: #a2a2a2;
            }
          }
        }
      }

      .kr-row3 {
        display: flex;
        padding-top: 40px;
        justify-content: center;

        .kr-btn1 {
          padding: 4px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 4px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #13b6ca;
          font-size: 14px;
          color: #13b6ca;
          cursor: pointer;
        }
      }
    }

    .kr-s7 {
      .kr-row1 {
        padding-top: 90px;
        margin-bottom: -40px;
      }

      /deep/ .accessory-box .ap-txt {
        margin-bottom: 0 !important;
      }
    }

    .kr-s8 {
      .kr-row1 {
        margin-top: -100px;
        padding-top: 90px;
        padding-bottom: 40px;
      }

      .kr-row2 {
        border-radius: 8px;
        padding: 24px 40px;
        position: relative;

        .g1 {
          position: absolute;
          z-index: -1;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }

        .kr-row2-1 {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          margin-bottom: 18px;

          .kr-t1 {
            text-align: left;
            min-width: 64px;
            font-size: 14px;
            color: #3d3d3d;
            line-height: 36px;
          }
        }

        .kr-row2-2 {
          display: flex;
          justify-content: center;

          .kr-btn1 {
            padding: 0 32px;
            height: 32px;
            font-size: 16px;
            line-height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px 4px 4px 4px;
            cursor: pointer;
            background: #00b0c6;
            color: white;
          }
        }

        .kr-row2-3 {
          padding-top: 40px;
          margin-bottom: 32px;
          font-size: 18px;
          color: #3d3d3d;
          padding-bottom: 8px;
          border-bottom: 2px solid #e5e5e5;
          text-align: left;
          font-weight: bold;
        }

        .kr-row2-4 {
          padding-bottom: 24px;
          display: flex;
          flex-direction: column;
          row-gap: 32px;

          .kr-row2-41 {
            text-align: left;

            .kr-t2 {
              color: #3d3d3d;
              font-size: 18px;
            }

            .kr-t3 {
              color: #bdbdbd;
              font-size: 14px;
            }

            .kr-t4 {
              color: #3d3d3d;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .CityNew {
    .kr-content {
      .kr-s4 {
        .kr-row {
          .kr-col {
            .kr-box {
              width: 327px;
              height: 48px;
            }
          }
        }
      }

      .kr-s5 {
        .kr-row1 {
          padding-top: 60px;
          padding-bottom: 20px;
        }
      }

      .kr-s8 {
        .kr-row2 {
          padding: 24px 24px;
        }
      }
    }
  }
}
</style>

<style scoped>
.el-header {
  padding: 0;
}

.el-main {
  margin-top: 0;
  padding: 0;
  overflow: initial;
}

.el-main .w {
  padding: 15px;
}

.top-img {
  position: relative;
}

.top-img-top {
  position: absolute;
  top: 45%;
  left: 5%;
  color: #ffffff;
  text-align: left;
}

.top-img-top p {
  font-size: 0.6rem;
  font-weight: 600;
}

.top-img-top span {
  font-size: 0.45rem;
  font-weight: 300;
}

.top-img .top-img-top:after {
  content: "";
  position: absolute;
  left: 0;
  top: -16px;
  height: 1px;
  width: 40%;
  background: #fff;
}
</style>
