<template>
    <div class="cityF1">
      <el-container>
        <el-header v-if="!hideLayout" style="padding: 0" height="100%">
          <vheader class="hidden-sm-and-down"></vheader>
          <ivheadera class="hidden-md-and-up"></ivheadera>
          <div class="top-img">
             <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/F1-topImg.png" alt="">
             <img class="hidden-md-and-up" width="100%" src="../../assets/images/en/A/F1-topImg-m.png" alt="">
             <div class="top-img-top">
               <p>About Us</p>
             </div>
           </div>
        </el-header>
        <div class="w aufa">
          <el-main class="aufa-mifa">
            <div v-if="!hideLayout" class="ec-top-foot">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item ><router-link to="/">首页</router-link></el-breadcrumb-item>
                <el-breadcrumb-item>平台介绍</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div class="platform-box">
              <div class="card-box">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="8" v-for="(item,index) in cardArr" :key="index">
                    <div class="card">
                      <el-col :xs="8" :sm="8" :md="24">
                        <div class="card-icon">
                          <img :src="item.IMAGE" alt="">
                          <div class="icon-cn-txt">{{item.TITLE}}</div>
                          <div class="icon-en-txt">{{item.ONE}}</div>
                        </div>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="24">
                        <div class="card-txt">
                          {{item.TWO}}
                        </div>
                      </el-col>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="webApp-box">
                <div class="title-img">
                  <img class="hidden-sm-and-down" width="40%" src="../../assets/images/F/title01.png" alt="">
                  <img class="hidden-md-and-up" width="45%" src="../../assets/images/F/title01-1.png" alt="">
                </div>
                <div class="wa-content">
                  <el-row>
                    <el-col :xs="24" :sm="24" :md="8">
                      <div class="wa-c-txt" v-html="webApp.ONE">
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="16">
                      <div class="wa-c-img">
                        <img width="100%" :src="webApp.IMAGE" alt="">
                      </div>
                    </el-col>
                    </el-row>
                    <el-row>
                    <div class="wa-card" v-for="(item,index) in waCardA" :key="index">
                    <el-col :xs="24" :sm="24" :md="6">
                      <div class="wa-card-icon">
                        <div class="wa-icon-num">
                          <h1>{{index>8?index+1:'0'+(index+1)}}</h1>
                        </div>
                        <div class="wa-icon-txt">
                          <div class="wa-icon-cn">{{item.TITLE}}</div>
                          <div class="wa-icon-en">{{item.ONE}}</div>
                        </div>
                      </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="18">
                      <div class="wa-card-txt" v-html="item.TWO">
                      </div>
                    </el-col>
                  </div>
                    <div class="hidden-md-and-down img-box">
                      <el-col :xs="24" :sm="24" :md="12" v-for="(item,index) in imgSrc" :key="index">
                         <img :src="item" alt="">
                      </el-col>
                    </div>
                    <div class="wa-card" v-for="(item,index) in waCardB" :key="'info2-'+index">
                      <el-col :xs="24" :sm="24" :md="6">
                        <div class="wa-card-icon">
                          <div class="wa-icon-num">
                            <h1>{{index>6?index+3:'0'+(index+3)}}</h1>
                          </div>
                          <div class="wa-icon-txt">
                            <div class="wa-icon-cn">{{item.TITLE}}</div>
                            <div class="wa-icon-en">{{item.ONE}}</div>
                          </div>
                        </div>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="18">
                        <div class="wa-card-txt" v-html="item.TWO">
                        </div>
                      </el-col>
                    </div>
                  </el-row>
                </div>
              </div>
              <div class="accessory-box">
                <div class="title-img">
                  <img class="hidden-sm-and-down" width="30%" src="../../assets/images/F/title02.png" alt="">
                  <img class="hidden-md-and-up" width="37%" src="../../assets/images/F/title02-2.png" alt="">
                </div>
                <div class="ap-txt" v-html="webApp.THREE">
                </div>
              </div>
            </div>
          </el-main>
        </div>
        <el-footer v-if="!hideLayout">
          <vfooter class="hidden-sm-and-down"></vfooter>
          <ivfooter class="hidden-md-and-up"></ivfooter>
        </el-footer>
      </el-container>
    </div>
</template>

<script>
import { ptintroduceA, ptintroduceB, ptintroduceC } from '../../api'
export default {
  props: {
    hideLayout: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cardArr: {},
      webApp: {},
      waCard: {},
      imgSrc: {},
      waCardA: {},
      waCardB: {}
    }
  },
  mounted () {
    ptintroduceA().then(res => {
      this.cardArr = res.data
      // console.log(this.cardArr)
    })
    ptintroduceB().then(res => {
      console.log(res.data[0].TWO)
      this.webApp = res.data[0]
      this.imgSrc = res.data[0].TWO
      // console.log(this.webApp)
      var a = this.imgSrc.split('</p>')
      // eslint-disable-next-line no-array-constructor
      var b = new Array()
      console.log(a)
      for (var i = 0; i < a.length; i++) {
        if (a[i].indexOf('src') !== -1) {
          b.push(a[i].match(/src="(\S*)"/)[1])
        }
      }
      this.imgSrc = b
      console.log(this.imgSrc)
    })
    ptintroduceC().then(res => {
      this.waCard = res.data
      // console.log(this.waCard)
      this.waCardA = this.waCard.slice(0, 2)
      this.waCardB = this.waCard.slice(2)
    })
  }
}
</script>

<style src="../../assets/css/CityF1.css">
</style>
<style scoped>
.el-main {
    margin-top: 0;
}
</style>
